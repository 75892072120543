<template>
  <b-card
    :header="this.$i18n.t('navigation.stickers-register')"
    header-bg-variant="primary"
    header-text-variant="white"
  >
    <GoBackButton />
    <b-modal id="main-image-modal" size="lg" ok-only>
      <img
        v-if="mainImageModalUrl"
        :src="mainImageModalUrl"
        alt="main image"
        class="mx-auto modal-image"
      />
    </b-modal>
    <validation-observer ref="stickerValidation">
      <div class="sticker-add-container">
        <b-form-group
          :label="t.t('products.name-geo') + ' *'"
          label-for="nameGeo"
          class="mb-0 w-100"
        >
          <validation-provider
            #default="{ errors }"
            :name="t.t('products.name-geo')"
            rules="required"
          >
            <b-form-input
              v-model="data.nameGeo"
              :placeholder="t.t('products.name-geo') + '...'"
              name="nameGeo"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          :label="t.t('products.name-eng') + ' *'"
          label-for="nameEng"
          class="mb-0 w-100"
        >
          <validation-provider
            #default="{ errors }"
            :name="t.t('products.name-eng')"
            rules="required"
          >
            <b-form-input
              v-model="data.nameEng"
              :placeholder="t.t('products.name-eng') + '...'"
              name="nameEng"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </div>
      <div class="sticker-add-container">
        <b-form-group
          :label="t.t('products.category') + ' *'"
          label-for="i-name-eng"
        >
          <validation-provider
            #default="{ errors }"
            :name="t.t('products.category')"
            rules="required"
          >
            <b-form-select
              v-model="selectedCategory"
              :name="t.t('products.category')"
            >
              <template #first>
                <b-form-select-option :value="null" disabled>
                  -- {{ t.t("products.please-chose") }}--
                </b-form-select-option>
              </template>
              <b-form-select-option-group
                v-for="cat in computedCategories"
                :key="cat.id + cat.label"
                :label="cat.label"
              >
                <b-form-select-option
                  v-for="subcat in cat.children"
                  :key="subcat.id + subcat.label"
                  :value="subcat"
                  :label="subcat.label"
                >
                  -->
                  {{ subcat.label }}
                </b-form-select-option>
              </b-form-select-option-group>
            </b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          v-if="selectedCategory"
          :label="t.t('products.generic-category') + ' *'"
          label-for="i-name-eng"
        >
          <validation-provider
            #default="{ errors }"
            :name="t.t('products.generic-category')"
            rules="required"
          >
            <b-form-select
              v-model="selectedGenericCategory"
              :name="t.t('products.generic-category')"
            >
              <b-form-select-option :value="null">
                -- {{ t.t("products.please-chose") }}--
              </b-form-select-option>
              <b-form-select-option
                v-for="gencat in selectedCategory.children"
                :key="gencat.id + gencat.label"
                :value="gencat"
                :label="gencat.label"
              >
                {{ gencat.label }}
              </b-form-select-option>
            </b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </div>
      <div class="mt-1 grid-4">
        <b-form-group
          :label="t.t('products.min-age') + ' *'"
          :name="t.t('products.minAge')"
          label-for="i-name-eng"
        >
          <validation-provider
            #default="{ errors }"
            :name="t.t('products.minAge')"
            rules="required"
          >
            <b-form-input
              id="i-eng"
              v-model="data.minAge"
              type="number"
              :placeholder="t.t('products.min-age')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          :label="t.t('products.max-age') + ' *'"
          :name="t.t('products.max-age')"
          label-for="i-name-eng"
        >
          <validation-provider
            #default="{ errors }"
            :name="t.t('products.max-age')"
            rules="required"
          >
            <b-form-input
              id="i-eng"
              v-model="data.maxAge"
              type="number"
              :placeholder="t.t('products.max-age')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          :label="t.t('products.target-genders')"
          label-for="i-name-eng"
        >
          <validation-provider
            #default="{ errors }"
            :name="t.t('products.target-genders')"
            rules="required"
          >
            <b-form-select
              v-model="data.targetGender"
              :placeholder="t.t('products.target-genders')"
              :name="t.t('products.target-genders')"
              :options="gendersOptions"
              value-field="value"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </div>
      <div class="mt-1 grid-4">
        <b-form-group
          :label="t.t('products.price') + ' *'"
          label-for="price"
          class="mb-0 w-100"
        >
          <validation-provider
            #default="{ errors }"
            :name="t.t('products.price')"
            rules="required"
          >
            <b-form-input
              v-model="data.price"
              :placeholder="t.t('products.price') + '...'"
              :name="t.t('products.price')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          :label="t.t('products.count') + ' *'"
          label-for="count"
          class="mb-0 w-100"
        >
          <validation-provider
            #default="{ errors }"
            :name="t.t('products.count')"
            rules="required"
          >
            <b-form-input
              v-model="data.count"
              :placeholder="t.t('products.count') + '...'"
              :name="t.t('products.count')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          :label="t.t('products.select-main') + ' *'"
          label-for="mainImage"
          class="mb-0 w-100"
        >
          <validation-provider
            #default="{ errors }"
            :name="t.t('products.select-main')"
            rules="required"
          >
            <b-form-file
              v-model="data.mainImage"
              :placeholder="t.t('products.select-main') + '...'"
              :name="t.t('products.select-main')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <span class="text-secondary">{{
            t.t("products.select-hint-sticker")
          }}</span>
        </b-form-group>
        <div>
          <img
            v-if="data.mainImage"
            :src="showMainImage(data.mainImage)"
            alt="main image"
            class="my-1 rounded thumb-image"
            @click="handleMainImageModal(showMainImage(data.mainImage))"
          />
        </div>
      </div>
      <div class="mt-1 grid-custom">
        <b-form-group
          :label="t.t('products.product-visibility')"
          label-for="dateRange"
        >
          <flat-pickr
            v-model="dateRange"
            label="dateRange"
            :placeholder="t.t('products.product-visibility')"
            class="date-control"
            :config="{
              enableTime: true,
              dateFormat: 'Y-m-d H:i:ss',
              mode: 'range',
            }"
          />
        </b-form-group>
        <div class="d-flex">
          <b-form-group :label="t.t('products.sale')" class="mr-2">
            <b-form-checkbox
              v-model="data.isSale"
              checked="true"
              name="check-button"
              switch
              inline
            >
              {{ t.t("products.sale") }}
            </b-form-checkbox>
          </b-form-group>
          <b-form-group :disabled="!data.isSale" :label="t.t('global.type')">
            <b-form-radio v-model="data.sale.type" :value="2">{{
              t.t("global.percent")
            }}</b-form-radio>
            <b-form-radio v-model="data.sale.type" :value="1">{{
              t.t("global.lari")
            }}</b-form-radio>
          </b-form-group>
        </div>
        <div class="sales-wrapper">
          <b-form-group
            v-if="data.sale.type === 2"
            :label="t.t('global.value')"
            class="ml-2 mr-2 percent-input"
          >
            <b-form-input
              v-model="data.sale.percent"
              :readonly="!data.isSale"
              placeholder="0"
              type="number"
            />
          </b-form-group>
          <b-form-group
            v-else
            :label="t.t('global.value')"
            class="ml-2 mr-2 percent-input"
          >
            <b-form-input
              v-model="data.sale.fixed"
              :readonly="!data.isSale"
              placeholder="0"
              type="number"
            />
          </b-form-group>
          <b-form-group :label="t.t('products.sale-until')">
            <flat-pickr
              v-model="data.saleUntilAt"
              :readonly="!data.isSale"
              :disabled="!data.isSale"
              :placeholder="t.t('products.sale-until')"
              class="form-control"
              :config="{
                enableTime: true,
                dateFormat: 'Y-m-d H:i:ss',
                mode: 'single',
              }"
            />
          </b-form-group>
        </div>
      </div>
      <div class="mt-1 grid-2">
        <b-form-group
          :label="t.t('products.description-geo')"
          label-for="descriptionGeo"
        >
          <b-form-textarea
            id="textarea-state"
            v-model="data.descriptionGeo"
            name="descriptionGeo"
            :placeholder="t.t('products.description-geo')"
            rows="2"
          />
        </b-form-group>
        <b-form-group
          :label="t.t('products.description-eng')"
          label-for="descriptionEng"
        >
          <b-form-textarea
            v-model="data.descriptionEng"
            name="descriptionEng"
            :placeholder="t.t('products.description-eng')"
            rows="2"
          />
        </b-form-group>
        <b-form-group :label="t.t('products.terms-geo')" label-for="termsGeo">
          <b-form-textarea
            v-model="data.useTermsGeo"
            name="termsGeo"
            :placeholder="t.t('products.terms-geo')"
            rows="2"
          />
        </b-form-group>
        <b-form-group :label="t.t('products.terms-eng')" label-for="termsEng">
          <b-form-textarea
            v-model="data.useTermsEng"
            name="termsEng"
            :placeholder="t.t('products.terms-eng')"
            rows="2"
          />
        </b-form-group>
      </div>
    </validation-observer>
    <div>
      <b-button
        :disabled="showLoading"
        variant="success"
        class="mt-1 ml-auto"
        @click.prevent="registerSicker"
      >
        {{ $t("global.add") }}
      </b-button>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BFormGroup,
  BFormInput,
  BButton,
  BFormSelect,
  BFormSelectOption,
  BFormSelectOptionGroup,
  BFormFile,
  BModal,
  BFormTextarea,
  BFormCheckbox,
  BFormRadio,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { mapState, mapActions } from "vuex";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import objectToFormData from "@/helpers/objectToFormData";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import GoBackButton from "../components/GoBackButton/GoBackButton.vue";

//

export default {
  name: "RegisterSticker",
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    BButton,
    BFormSelect,
    BFormSelectOption,
    BFormSelectOptionGroup,
    BFormFile,
    BModal,
    BFormTextarea,
    flatPickr,
    BFormCheckbox,
    GoBackButton,
    BFormRadio,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    isApproved: {
      type: Boolean,
      default: false,
    },
    isDuplicate: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    data: {
      nameEng: null,
      nameGeo: null,
      productCategoryId: null,
      productSubCategoryId: null,
      productGenericCategoryId: null,
      minAge: 25,
      maxAge: 50,
      targetGender: null,
      price: null,
      count: null,
      mainImage: null,
      //
      descriptionGeo: null,
      descriptionEng: null,
      useTermsGeo: null,
      useTermsEng: null,
      activeFrom: null,
      activeTo: null,
      //
      //
      isSale: null,
      saleUntilAt: null,
      sale: {
        type: null,
        fixed: null,
        percent: null,
      },
    },
    gendersOptions: [
      {
        text: "Male",
        value: 1,
      },
      {
        text: "Female",
        value: 2,
      },
      {
        text: "Both",
        value: 3,
      },
    ],
    selectedCategory: null,
    selectedGenericCategory: null,
    t: null,
    mainImageModalUrl: null,
    dateRange: null,
    required,
  }),
  computed: {
    ...mapState("products", [
      "productCategories",
      "productAttributes",
      "branches",
      "regions",
      "showLoading",
      "isConfigurable",
      "product",
      "showLoading",
    ]),
    computedCategories() {
      return this.productCategories.filter((e) =>
        e.children.some((b) => b.serviceType === 4)
      );
    },
  },
  watch: {
    product() {
      if (this.isEdit) {
        this.getProductCategories({
          take: 100,
          skip: 0,
        }).then(() => {
          if (this.isEdit && this.isEdit) {
            this.handleEditProductValues();
            this.handleCategoryOnEdit();
          }
        });
      }
    },
    dateRange(value) {
      const payload = value.split(" to ");
      // eslint-disable-next-line prefer-destructuring
      this.data.activeFrom = payload[0];
      // eslint-disable-next-line prefer-destructuring
      this.data.activeTo = payload[1];
    },
  },
  created() {
    this.t = this.$i18n;
    console.log("dublicating sticker", this.isDuplicate);
    if (!this.isEdit) {
      this.getProductCategories({
        take: 100,
        skip: 0,
      });
    }
  },
  methods: {
    toast(text = "") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: text,
          icon: "BellIcon",
          variant: "success",
        },
      });
    },
    handleCategoryOnEdit() {
      this.computedCategories.forEach((c) => {
        c.children.forEach((b) => {
          if (b.id === this.product.productSubCategory.productSubCategoryId) {
            this.selectedCategory = b;
          }
        });
      });
      console.log(this.selectedCategory);
      this.selectedGenericCategory = this.selectedCategory.children.find(
        (e) =>
          e.id === this.product.productGenericCategory.productGenericCategoryId
      );
    },
    handleEditProductValues() {
      const p = this.product;
      this.data = {
        nameEng: p.nameEng,
        nameGeo: p.nameGeo,
        productCategoryId: p.productCategory.productCategoryId,
        productSubCategoryId: p.productSubCategory.productSubCategoryId,
        productGenericCategoryId:
          p.productGenericCategory.productGenericCategoryId,
        minAge: p.minAge,
        maxAge: p.maxAge,
        targetGender: p.productTargetGender,
        price: p.price,
        count: p.count,
        mainImage: this.isDuplicate ? null : p.mainImage,
        descriptionGeo: p.descriptionGeo,
        descriptionEng: p.descriptionEng,
        useTermsGeo: p.useTermsGeo,
        useTermsEng: p.useTermsEng,
        activeFrom: p.activeFrom,
        activeTo: p.activeTo,
        isSale: !!p.sale,
        saleUntilAt: p.saleUntilAt,
      };
      this.dateRange = `${p.activeFrom} to ${p.activeTo}`;

      if (p.sale) {
        this.data.sale = p.sale;
      } else {
        this.data.sale = {
          type: null,
          fixed: null,
          percent: null,
        };
      }
    },
    showMainImage(e) {
      if (e.type) {
        return URL.createObjectURL(e);
      }
      return e.url;
    },
    handleMainImageModal(imageUrl) {
      this.mainImageModalUrl = imageUrl;
      this.$bvModal.show("main-image-modal");
    },
    handleCategoriesOnSubmit() {
      this.data.productCategoryId =
        this.selectedGenericCategory.productCategoryId;
      this.data.productSubCategoryId =
        this.selectedGenericCategory.productSubCategoryId;
      this.data.productGenericCategoryId = this.selectedGenericCategory.id;
    },
    registerSicker() {
      this.$refs.stickerValidation.validate().then((result) => {
        if (result) {
          this.handleCategoriesOnSubmit();
          const fd = objectToFormData(this.data);
          if (this.isEdit && !this.isDuplicate) {
            if (this.data.mainImage.type) {
              fd.append("MainImageUpdate.isPreviousDeleted", true);
              fd.append("MainImageUpdate.newImage", this.data.mainImage);
            } else {
              fd.append("MainImageUpdate.isPreviousDeleted", false);
            }
            const payload = {
              approved: this.isApproved,
              id: this.product.id,
              formData: fd,
            };
            this.updateSticker(payload).then(() => {
              this.toast("Sticker updated successfully");
            });
          } else {
            this.createSticker(fd).then((resp) => {
              if (resp) {
                this.$router.push({ name: "pending-products" });
              }
            });
          }
        } else {
          console.log("error");
        }
      });
    },
    ...mapActions("products", [
      "getProductAttributes",
      "getProductCategories",
      "editProduct",
      "createProduct",
      "createSticker",
      "updateSticker",
    ]),
  },
};
</script>

<style scoped lang="scss">
.sticker-add-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 1rem;
}

.thumb-image {
  width: auto;
  max-height: 150px;
  cursor: pointer;
  margin-right: 1rem;

  &:hover {
    opacity: 0.8;
  }
}

.grid-4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 1rem;
}

.grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 1rem;
}

.grid-custom {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  grid-template-rows: auto;
  grid-gap: 1rem;
}

.modal-image {
  margin: 0 auto;
  max-width: 100%;
  object-fit: cover;
}

.date-control {
  width: 100%;
  padding: 0.438rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  display: block;
}
.sales-wrapper {
  display: flex;
  align-items: center;
}
</style>
