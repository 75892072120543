<template>
  <div>
    <b-button
      size="sm"
      class="ml-auto"
      variant="outline-success"
      style="display: block"
      @click="handleGoBackButton"
    >
      <feather-icon icon="ArrowLeftCircleIcon" class="mr-25" />
      {{ $t("global.go-back") }}
    </b-button>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";

export default {
  components: {
    BButton,
  },
  methods: {
    handleGoBackButton() {
      this.$bvModal
        .msgBoxConfirm(this.$t("global.sure-go-back"))
        .then((result) => {
          if (result) this.$router.go(-1);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style></style>
