var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"header":this.$i18n.t('navigation.stickers-register'),"header-bg-variant":"primary","header-text-variant":"white"}},[_c('GoBackButton'),_c('b-modal',{attrs:{"id":"main-image-modal","size":"lg","ok-only":""}},[(_vm.mainImageModalUrl)?_c('img',{staticClass:"mx-auto modal-image",attrs:{"src":_vm.mainImageModalUrl,"alt":"main image"}}):_vm._e()]),_c('validation-observer',{ref:"stickerValidation"},[_c('div',{staticClass:"sticker-add-container"},[_c('b-form-group',{staticClass:"mb-0 w-100",attrs:{"label":_vm.t.t('products.name-geo') + ' *',"label-for":"nameGeo"}},[_c('validation-provider',{attrs:{"name":_vm.t.t('products.name-geo'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":_vm.t.t('products.name-geo') + '...',"name":"nameGeo"},model:{value:(_vm.data.nameGeo),callback:function ($$v) {_vm.$set(_vm.data, "nameGeo", $$v)},expression:"data.nameGeo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"mb-0 w-100",attrs:{"label":_vm.t.t('products.name-eng') + ' *',"label-for":"nameEng"}},[_c('validation-provider',{attrs:{"name":_vm.t.t('products.name-eng'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":_vm.t.t('products.name-eng') + '...',"name":"nameEng"},model:{value:(_vm.data.nameEng),callback:function ($$v) {_vm.$set(_vm.data, "nameEng", $$v)},expression:"data.nameEng"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"sticker-add-container"},[_c('b-form-group',{attrs:{"label":_vm.t.t('products.category') + ' *',"label-for":"i-name-eng"}},[_c('validation-provider',{attrs:{"name":_vm.t.t('products.category'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"name":_vm.t.t('products.category')},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v(" -- "+_vm._s(_vm.t.t("products.please-chose"))+"-- ")])]},proxy:true}],null,true),model:{value:(_vm.selectedCategory),callback:function ($$v) {_vm.selectedCategory=$$v},expression:"selectedCategory"}},_vm._l((_vm.computedCategories),function(cat){return _c('b-form-select-option-group',{key:cat.id + cat.label,attrs:{"label":cat.label}},_vm._l((cat.children),function(subcat){return _c('b-form-select-option',{key:subcat.id + subcat.label,attrs:{"value":subcat,"label":subcat.label}},[_vm._v(" --> "+_vm._s(subcat.label)+" ")])}),1)}),1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.selectedCategory)?_c('b-form-group',{attrs:{"label":_vm.t.t('products.generic-category') + ' *',"label-for":"i-name-eng"}},[_c('validation-provider',{attrs:{"name":_vm.t.t('products.generic-category'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"name":_vm.t.t('products.generic-category')},model:{value:(_vm.selectedGenericCategory),callback:function ($$v) {_vm.selectedGenericCategory=$$v},expression:"selectedGenericCategory"}},[_c('b-form-select-option',{attrs:{"value":null}},[_vm._v(" -- "+_vm._s(_vm.t.t("products.please-chose"))+"-- ")]),_vm._l((_vm.selectedCategory.children),function(gencat){return _c('b-form-select-option',{key:gencat.id + gencat.label,attrs:{"value":gencat,"label":gencat.label}},[_vm._v(" "+_vm._s(gencat.label)+" ")])})],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,730366427)})],1):_vm._e()],1),_c('div',{staticClass:"mt-1 grid-4"},[_c('b-form-group',{attrs:{"label":_vm.t.t('products.min-age') + ' *',"name":_vm.t.t('products.minAge'),"label-for":"i-name-eng"}},[_c('validation-provider',{attrs:{"name":_vm.t.t('products.minAge'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"i-eng","type":"number","placeholder":_vm.t.t('products.min-age')},model:{value:(_vm.data.minAge),callback:function ($$v) {_vm.$set(_vm.data, "minAge", $$v)},expression:"data.minAge"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.t.t('products.max-age') + ' *',"name":_vm.t.t('products.max-age'),"label-for":"i-name-eng"}},[_c('validation-provider',{attrs:{"name":_vm.t.t('products.max-age'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"i-eng","type":"number","placeholder":_vm.t.t('products.max-age')},model:{value:(_vm.data.maxAge),callback:function ($$v) {_vm.$set(_vm.data, "maxAge", $$v)},expression:"data.maxAge"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.t.t('products.target-genders'),"label-for":"i-name-eng"}},[_c('validation-provider',{attrs:{"name":_vm.t.t('products.target-genders'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"placeholder":_vm.t.t('products.target-genders'),"name":_vm.t.t('products.target-genders'),"options":_vm.gendersOptions,"value-field":"value"},model:{value:(_vm.data.targetGender),callback:function ($$v) {_vm.$set(_vm.data, "targetGender", $$v)},expression:"data.targetGender"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"mt-1 grid-4"},[_c('b-form-group',{staticClass:"mb-0 w-100",attrs:{"label":_vm.t.t('products.price') + ' *',"label-for":"price"}},[_c('validation-provider',{attrs:{"name":_vm.t.t('products.price'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":_vm.t.t('products.price') + '...',"name":_vm.t.t('products.price')},model:{value:(_vm.data.price),callback:function ($$v) {_vm.$set(_vm.data, "price", $$v)},expression:"data.price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"mb-0 w-100",attrs:{"label":_vm.t.t('products.count') + ' *',"label-for":"count"}},[_c('validation-provider',{attrs:{"name":_vm.t.t('products.count'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":_vm.t.t('products.count') + '...',"name":_vm.t.t('products.count')},model:{value:(_vm.data.count),callback:function ($$v) {_vm.$set(_vm.data, "count", $$v)},expression:"data.count"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"mb-0 w-100",attrs:{"label":_vm.t.t('products.select-main') + ' *',"label-for":"mainImage"}},[_c('validation-provider',{attrs:{"name":_vm.t.t('products.select-main'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"placeholder":_vm.t.t('products.select-main') + '...',"name":_vm.t.t('products.select-main')},model:{value:(_vm.data.mainImage),callback:function ($$v) {_vm.$set(_vm.data, "mainImage", $$v)},expression:"data.mainImage"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('span',{staticClass:"text-secondary"},[_vm._v(_vm._s(_vm.t.t("products.select-hint-sticker")))])],1),_c('div',[(_vm.data.mainImage)?_c('img',{staticClass:"my-1 rounded thumb-image",attrs:{"src":_vm.showMainImage(_vm.data.mainImage),"alt":"main image"},on:{"click":function($event){_vm.handleMainImageModal(_vm.showMainImage(_vm.data.mainImage))}}}):_vm._e()])],1),_c('div',{staticClass:"mt-1 grid-custom"},[_c('b-form-group',{attrs:{"label":_vm.t.t('products.product-visibility'),"label-for":"dateRange"}},[_c('flat-pickr',{staticClass:"date-control",attrs:{"label":"dateRange","placeholder":_vm.t.t('products.product-visibility'),"config":{
            enableTime: true,
            dateFormat: 'Y-m-d H:i:ss',
            mode: 'range',
          }},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1),_c('div',{staticClass:"d-flex"},[_c('b-form-group',{staticClass:"mr-2",attrs:{"label":_vm.t.t('products.sale')}},[_c('b-form-checkbox',{attrs:{"checked":"true","name":"check-button","switch":"","inline":""},model:{value:(_vm.data.isSale),callback:function ($$v) {_vm.$set(_vm.data, "isSale", $$v)},expression:"data.isSale"}},[_vm._v(" "+_vm._s(_vm.t.t("products.sale"))+" ")])],1),_c('b-form-group',{attrs:{"disabled":!_vm.data.isSale,"label":_vm.t.t('global.type')}},[_c('b-form-radio',{attrs:{"value":2},model:{value:(_vm.data.sale.type),callback:function ($$v) {_vm.$set(_vm.data.sale, "type", $$v)},expression:"data.sale.type"}},[_vm._v(_vm._s(_vm.t.t("global.percent")))]),_c('b-form-radio',{attrs:{"value":1},model:{value:(_vm.data.sale.type),callback:function ($$v) {_vm.$set(_vm.data.sale, "type", $$v)},expression:"data.sale.type"}},[_vm._v(_vm._s(_vm.t.t("global.lari")))])],1)],1),_c('div',{staticClass:"sales-wrapper"},[(_vm.data.sale.type === 2)?_c('b-form-group',{staticClass:"ml-2 mr-2 percent-input",attrs:{"label":_vm.t.t('global.value')}},[_c('b-form-input',{attrs:{"readonly":!_vm.data.isSale,"placeholder":"0","type":"number"},model:{value:(_vm.data.sale.percent),callback:function ($$v) {_vm.$set(_vm.data.sale, "percent", $$v)},expression:"data.sale.percent"}})],1):_c('b-form-group',{staticClass:"ml-2 mr-2 percent-input",attrs:{"label":_vm.t.t('global.value')}},[_c('b-form-input',{attrs:{"readonly":!_vm.data.isSale,"placeholder":"0","type":"number"},model:{value:(_vm.data.sale.fixed),callback:function ($$v) {_vm.$set(_vm.data.sale, "fixed", $$v)},expression:"data.sale.fixed"}})],1),_c('b-form-group',{attrs:{"label":_vm.t.t('products.sale-until')}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"readonly":!_vm.data.isSale,"disabled":!_vm.data.isSale,"placeholder":_vm.t.t('products.sale-until'),"config":{
              enableTime: true,
              dateFormat: 'Y-m-d H:i:ss',
              mode: 'single',
            }},model:{value:(_vm.data.saleUntilAt),callback:function ($$v) {_vm.$set(_vm.data, "saleUntilAt", $$v)},expression:"data.saleUntilAt"}})],1)],1)],1),_c('div',{staticClass:"mt-1 grid-2"},[_c('b-form-group',{attrs:{"label":_vm.t.t('products.description-geo'),"label-for":"descriptionGeo"}},[_c('b-form-textarea',{attrs:{"id":"textarea-state","name":"descriptionGeo","placeholder":_vm.t.t('products.description-geo'),"rows":"2"},model:{value:(_vm.data.descriptionGeo),callback:function ($$v) {_vm.$set(_vm.data, "descriptionGeo", $$v)},expression:"data.descriptionGeo"}})],1),_c('b-form-group',{attrs:{"label":_vm.t.t('products.description-eng'),"label-for":"descriptionEng"}},[_c('b-form-textarea',{attrs:{"name":"descriptionEng","placeholder":_vm.t.t('products.description-eng'),"rows":"2"},model:{value:(_vm.data.descriptionEng),callback:function ($$v) {_vm.$set(_vm.data, "descriptionEng", $$v)},expression:"data.descriptionEng"}})],1),_c('b-form-group',{attrs:{"label":_vm.t.t('products.terms-geo'),"label-for":"termsGeo"}},[_c('b-form-textarea',{attrs:{"name":"termsGeo","placeholder":_vm.t.t('products.terms-geo'),"rows":"2"},model:{value:(_vm.data.useTermsGeo),callback:function ($$v) {_vm.$set(_vm.data, "useTermsGeo", $$v)},expression:"data.useTermsGeo"}})],1),_c('b-form-group',{attrs:{"label":_vm.t.t('products.terms-eng'),"label-for":"termsEng"}},[_c('b-form-textarea',{attrs:{"name":"termsEng","placeholder":_vm.t.t('products.terms-eng'),"rows":"2"},model:{value:(_vm.data.useTermsEng),callback:function ($$v) {_vm.$set(_vm.data, "useTermsEng", $$v)},expression:"data.useTermsEng"}})],1)],1)]),_c('div',[_c('b-button',{staticClass:"mt-1 ml-auto",attrs:{"disabled":_vm.showLoading,"variant":"success"},on:{"click":function($event){$event.preventDefault();return _vm.registerSicker.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("global.add"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }