<template>
  <register-sticker v-if="product" :is-edit="true" :is-approved="isApproved" :is-duplicate="isDuplicate" />
</template>

<script>
// eslint-disable-next-line import/no-cycle
import router from "@/router";
import { mapActions, mapState } from "vuex";
import RegisterSticker from "./RegisterSticker.vue";

export default {
  name: "EditSticker",
  components: { RegisterSticker },
  return() {
    return {
      isApproved: false,
      isDuplicate: false,
    };
  },
  computed: {
    ...mapState("products", ["product"]),
  },
  created() {
    const { id, isApproved, isDuplicate } = router.currentRoute.params;
    this.isDuplicate = isDuplicate;
    if (isApproved) {
      this.isApproved = true;
      this.getApprovedProductById(id);
    } else {
      this.isApproved = false;
      this.getProductById(id);
    }
  },
  methods: {
    ...mapActions("products", ["getProductById", "getApprovedProductById"]),
  },
};
</script>

<style></style>
